document.querySelector('[data-nav-trigger="primary"]').addEventListener('click', () => {
    document.querySelector('.nav').classList.toggle('is-active')
})

const list = document.querySelector('.list')
const listItems = list ? [...list.querySelectorAll('li')] : null
const featuresCards = [...document.querySelectorAll('.features-card')]

//List observer options
const options = {
    root: null,
    rootMargin: "60px",
    threshold: 1.0,
};

const cardOptions = {
    root: null,
    rootMargin: "60px",
    threshold: 1.0,
};

const callback = (entries, observer) => {
    entries.forEach((entry) => {
        const intersecting = entry.isIntersecting
        let elem = entry.target
        if(intersecting) {
            elem.classList.add('is-active')
            if(elem.classList.contains('list__item is-active')){
                observer.unobserve(elem)
            }

            console.log(elem.classList.contains('list__item'))
        } else {
            setTimeout(()=> {
                elem.classList.remove('is-active')

            }, 150)

        }
    });
}

const scrollHandler = function(e)  {
    console.log(e)
    let atSnappingPoint = e.target.scrollLeft % e.target.offsetWidth === 0;
    let timeOut         = atSnappingPoint ? 0 : 150;

    clearTimeout(e.target.scrollTimeout); //clear previous timeout

    e.target.scrollTimeout = setTimeout(function() {
        //using the timeOut to evaluate scrolling state
        if (!timeOut) {
            console.log('Scroller snapped!');


        } else {
            console.log('User stopped scrolling.');


        }

    }, timeOut);

}

const observer = new IntersectionObserver(callback, options);
const cardObserver = new IntersectionObserver(callback, cardOptions);

//List items loop
listItems?.forEach((item, index) => {

    if(window.innerWidth < 786) {
        observer.observe(item);
    }


    item.addEventListener('mouseenter', (e) => {

        if(index === 0) {
            return
        }

        //last element
        if(index === listItems.length - 1) {
           listItems.forEach(el => {
               el.classList.add('is-active')
           })
        }

        if(!item.classList.contains('is-active')) {
            item.classList.add('is-active')
            observer.unobserve(item)
        }
    })

    item.addEventListener('mouseleave', (e) => {

        if(index === 0) {
            return
        }

        if(item.classList.contains('is-active')) {
            item.classList.remove('is-active')
        }

        //last element
        if(index === listItems.length - 1 ) {
            listItems.forEach((el, index) => {
                if(index === 0) {
                    return
                }
                el.classList.remove('is-active')
            })
        }
    })
})

featuresCards.forEach((card,index) => {
    if(window.innerWidth < 786) {
        cardObserver.observe(card)
    }

})



